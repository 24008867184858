<template>
  <div>
    <Header :showTitle="false" :showLogo="true" :showBackButton="true" />

    <section class="page-content page-anim2">
      <div class="container pb-3">
        <h1 v-show="!loading" class="title section-title category text-blue">
          {{ t("Novidades") }}
        </h1>

        <transition enter-active-class="animated zoomIn">
          <div
            v-show="!loading"
            class="cards-home-holder mt-1-mobile mt-1-tablet mt-2-desktop"
          >
            <div
              @click="$router.push(`/template/${template.id}`)"
              v-for="template in latestTemplates"
              :key="template.id"
              class="category-card"
            >
              <div class="img-holder">
                <img class="bg-img" :src="template.coverImage" />
              </div>
              <p class="card-title text-blue">{{ template.title }}</p>

              <!-- <p class="description card-desc text-blue">
              {{ cat.description }}
            </p> -->
            </div>
          </div>
        </transition>

        <div v-show="loading" class="flex-center mt-2">
          <div class="loading-spinner blue"></div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import http from "@/http";
import Header from "../components/Header";
import authentication from "../util/authentication";
export default {
  name: "Novidades",
  components: {
    Header,
  },
  data() {
    return {
      loading: true,
      latestTemplates: [],
    };
  },
  created() {
    this.getLatestTemplates();
  },
  methods: {
    getLatestTemplates() {
      http
        .get(`template?take=30`, authentication.getHeaders())
        .then((response) => {
          this.latestTemplates = response.data.list;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.news-title-controls {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 22px;
  margin-bottom: 10px;
  .controls {
    position: relative;
    width: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      font-size: 1.4em;
      cursor: pointer;
      color: var(--blue);
      &.swiper-button-disabled {
        opacity: 0.3;
      }
    }
  }
}

.title.category {
  margin-top: 22px;
}

.prod {
  position: relative;
  width: 110px !important;
  overflow: hidden;
  transition: margin 0.2s ease;
}

.prod img {
  position: relative;
  width: 100%;
}

.prod.last {
  height: 135px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  border-radius: 10px;
}

.prod.last:hover > svg,
.prod.last:hover > p {
  color: var(--primary);
}

.prod.last svg {
  font-size: 2.4em;
  transition: color 0.25s ease;
}

.prod.last p {
  margin: 12px 10px 0 12px;
  font-size: 1.1em;
  text-align: center;
  transition: color 0.25s ease;
}
</style>
